import request from "@/utils/request";

// 获取图形验证码
export function MatchList(data) {
	return request({
    url: "/search/MatchList",
    method: "post",
    data: data,
  });
}
// 数量查询
export function cal_quantity() {
	return request({
    url: "/cal_quantity",
    method: "post",
  });
}